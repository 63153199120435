<script>
import { onMounted, ref, toRef } from "vue";

export default {
  name: "NotaryListComponent",
  props: {
    notaries: {
      type: Array,
      required: true
    },
    notaryForm: {
      type: Object,
      required: true
    },
    clearNotaryHandler: {
      type: Function,
      required: true
    }
  },
  setup(props) {

    const currentIndex = ref(0);
    const notaryForm = toRef(props, 'notaryForm');
    const notaries = toRef(props, 'notaries');

    const handleSelect = (i) => {
      currentIndex.value = i;
      notaryForm.value.data = notaries.value[currentIndex.value];
    };

    onMounted(() => {
      handleSelect(currentIndex.value);
    });

    return {
      currentIndex,
      handleSelect
    }
  }
}
</script>

<template>
  <div class="notary-list">
    <div class="form-group">
      <label>Найдены наследственные дела у нотариуса:</label>
      <div class="notary-items">
        <div v-for="(notary, index) in notaries" :key="index">
          <input
            type="radio"
            :value="index"
            :checked="currentIndex !== null && currentIndex === index"
            @click="handleSelect(index)"
          />
          <span>{{ notary.notaryName }} ({{ notary.chamberName }}), №{{ notary.caseNumber }}, Дата смерти: {{ notary.deathDate }}</span>
        </div>
      </div>
      <div class="info-text">
        <p v-if="notaries.length>1">Выберите нужное дело о наследстве.</p>
        <p>Если данные корректны, то отправляйте Заказ на обработку.</p>
        <p>Если нет, то укажите нужного нотариуса вручную по его ФИО</p>
        <p><a href="#" @click="clearNotaryHandler">Выбрать нотариуса вручную</a></p>
      </div>
    </div>
  </div>
</template>

<style scoped>

.notary-items input {
  margin-right: 5px;
}

.form-group {
  margin-bottom: 0;
}

.info-text {
  margin-top: 10px;
}

.info-text>p {
  margin-bottom: 5px;
}

.info-text>p:last-child {
  margin-bottom: 0;
}

</style>
