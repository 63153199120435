<script>
import { ref, toRef } from "vue";
import { findByNameRequest } from "../../../../../api/notaries";

export default {
  name: "SearchLocalComponent",
  props: {
    isFindRequestTimeOut: {
      type: Boolean,
      required: true
    },
    form: {
      type: Object,
      required: true
    }
  },
  setup(props) {

    const notaries = ref([]);
    const value = ref('');
    const form = toRef(props, 'form');

    const handleClearItems = () => {
      notaries.value = [];
      value.value = '';
    };

    const handleNotarySearch = () => {
      findByNameRequest({
        name: value.value
      }).then(response => {
        const data = response.data;
        if(data.length) {
          notaries.value = data;
        } else {
          handleClearItems();
        }
      }).catch(err => console.log(err));
    };

    const handleSelectNotary = (e,notaryItem) => {
      e.preventDefault();
      form.value.data = notaryItem;
      handleClearItems();
    };

    return {
      notaries,
      handleClearItems,
      handleNotarySearch,
      value,
      handleSelectNotary,
      form
    }
  }
}
</script>

<template>
  <div class="notary-custom">
    <div class="inner">
      <div>
        <p v-if="isFindRequestTimeOut === true" class="red">
          Не смогли определить нотариуса, т.к. федеральный реестр нотариальных дел в данный момент не отвечает - вы можете указать номер дела и нотариуса вручную или попробовать еще раз позже.
        </p>
        <p v-else class="blue">По указанным ФИО/дате смерти дело о наследстве не найдено.</p>
        <p>Проверьте введенные данные. Если нужно - скорректируйте и мы проверим еще раз.</p>
        <p>Если вы уверены во введенных данных, то выберите вашего нотариуса по ФИО:</p>
      </div>
      <div class="form-group">
        <input
          type="text"
          class="form-control"
          placeholder="Введите ФИО нотариуса"
          v-model="value"
          @keyup="handleNotarySearch"
        />
        <ul class="notary-list" v-if="notaries.length">
          <li v-for="notary in notaries" :key="notary.id">
            <a href="#" @click="(e) => handleSelectNotary(e,notary)">{{ notary.name }} ({{notary.region.name}})</a>
          </li>
        </ul>
      </div>
      <div v-if="form.data" class="selected-notary">
        <div>
          <label>Выбран нотариус: </label>
          <span class="blue">
            {{ form.data.name }} ({{ form.data.region.name }})
          </span>
        </div>
        <div class="notary-setting">
          <div class="form-group">
            <h4>Укажите № наследственного дела, если он Вам известен:</h4>
          </div>
          <div class="form-group">
            <input type="text" class="form-control" placeholder="№ насл.дела" v-model="form.number" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="notaries.length" class="overlay" @click="handleClearItems"></div>
  </div>
</template>

<style scoped>

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.notary-custom p {
  margin-bottom: 5px;
}

.notary-custom .form-group {
  margin-bottom: 0;
  position: relative;
}

.notary-list {
  position: absolute;
  left: 0;
  top: 33.5px;
  z-index: 2;
  width: 100%;
  max-height: 145px;
  overflow-y: auto;
}

.notary-list li a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 7px 10px;
  background-color: #ffffff;
  border: 1px solid #CCC;
  border-top: none;
  text-decoration: none;
  color: #000000;
}

.notary-list li a:hover {
  background-color: #ededed;
}

.selected-notary {
  margin-top: 5px;
  padding: 10px;
  border: 1px dashed #a5a5a5;
}

.selected-notary label {
  font-weight: bold;
  margin-bottom: 5px;
  display: inline-block;
  margin-right: 5px;
}

.notary-setting {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.notary-setting .form-group:first-child {
  width: 71%;
}

.notary-setting .form-group:last-child {
  width: 29%;
}

.notary-setting h4 {
  margin-bottom: 3px;
  font-weight: normal;
}

.blue {
  color: #3294ca;
}

@media (max-width: 768px) {

  .notary-setting {
    display: block;
  }

  .notary-setting .form-group:first-child,
  .notary-setting .form-group:last-child {
    width: 100%;
  }

}

</style>
