<script>
export default {
  name: "ObjectsComponent",
  props: {
    objects: {
      type: Array,
      required: true
    },
    reportType: {
      type: Number,
      required: true
    },
    paperReportPrice: {
      type: Number,
      required: true
    },
    deleteItemEvent: {
      type: Function,
      required: true
    }
  }
}
</script>

<template>
  <div class="services">
    <div v-for="(object, objectIndex) in objects" :key="object.id" class="service-item">
      <div class="service-item-header">
        <div class="name">
          <router-link :to="'/oc/' + object.page.alias">
            {{ objectIndex+1 }}. {{ object.name }}
          </router-link>
          <font-awesome-icon icon="info-circle" />
        </div>
      </div>
      <div class="service-item-body">
        <div class="image">
          <img :src="object.image">
        </div>
        <div class="price">
          <template v-if="!object.priceSale">
            <span>{{ object.price }} руб.</span>
          </template>
          <template v-else>
            <span class="price_sale">{{object.price}} руб.</span>
            <span>{{ object.priceSale }} руб.</span>
          </template>
          <p v-if="reportType===2">+{{ paperReportPrice }} руб.</p>
        </div>
        <div class="action">
          <button class="btn btn-custom" @click="deleteItemEvent(object.dbIndex)">
            <font-awesome-icon icon="trash" />
          </button>
        </div>
      </div>
      <div class="service-item-footer">
        <div class="prompt">
          <div><b>Потребуются документы:</b></div>
          <div v-html="object.prompt"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.services .service-item {
  margin: 10px 0;
  padding: 10px;
}

.services .service-item:nth-child(odd) {
  background-color: #f5f5f5;
}

.services .service-item:nth-child(even) {
  background-color: #f5f5f5;
}

.services .service-item-header .name {
  display: inline-block;
}

.services .service-item .name span {
  margin-bottom: 10px;
  color: #000;
  display: block;
}

.services .service-item .name a {
  border-bottom: 1px dashed #000000;
  text-decoration: none;
  display: inline-block;
  margin-right: 5px;
  color: #000;
  margin-bottom: 10px;
}

.services .service-item .name a:hover {
  border-bottom: 1px dashed transparent;
}

.services .service-item .name svg {
  color: #b1b1b1;
}

.services .service-item-body {
  margin-bottom: 5px;
}

.services .service-item-body .image,
.services .service-item-body .price,
.services .service-item-body .action {
  display: inline-block;
  vertical-align: middle;
}

.services .service-item-body .image {
  width: 30%;
}

.services .service-item-body .price {
  width: 55%;
  text-align: center;
}

.services .service-item-body .action {
  width: 15%;
  text-align: right;
}

.services .service-item-body .price_sale {
  color: red;
  text-decoration: line-through;
  margin-right: 5px;
}

</style>
