<script>
export default {
  name: "ObjectsComponent",
  props: {
    objects: {
      type: Array,
      required: true
    },
    reportType: {
      type: Number,
      required: true
    },
    paperReportPrice: {
      type: Number,
      required: true
    },
    deleteItemEvent: {
      type: Function,
      required: true
    }
  }
}
</script>

<template>
  <div class="services">
    <div class="table">
      <div class="tr">
        <div class="th num">№</div>
        <div class="th">Услуга</div>
        <div class="th price">Цена</div>
        <div class="th action"></div>
      </div>
      <div v-for="(object, objectIndex) in objects" :key="object.id" class="tr">
        <div class="td num">{{ objectIndex+1 }}</div>
        <div class="td info-col">
          <div>
            <img class="image" :src="object.image">
            <div class="info">
              <div class="name">
                <router-link :to="'/oc/' + object.page.alias">
                  {{ object.page.name }}
                </router-link>
              </div>
              <span class="prompt">
                <span>Потребуются документы:</span>
                <span v-html="object.prompt"></span>
              </span>
            </div>
          </div>
        </div>
        <div class="td price">
          <template v-if="!object.priceSale">
            <p>{{ object.price }} руб.</p>
          </template>
          <template v-else>
            <p class="price_sale">{{ object.price }} руб.</p>
            <p>{{ object.priceSale }} руб.</p>
          </template>
          <p v-if="reportType === 2">+{{ paperReportPrice }} руб.</p>
        </div>
        <div class="td action">
          <button class="btn btn-custom" @click="deleteItemEvent(object.dbIndex)">
            <font-awesome-icon icon="trash" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.services .table {
  width: 100%;
}

.services .table .td,
.services .table .th {
  padding: 5px;
  vertical-align: middle;
}

.services .table .tr:nth-child(2n) {
  background-color: #f3f3f3;
}

.services .table .price {
  width: 100px;
  text-align: center;
}

.services .table .price p {
  margin-bottom: 0;
}

.services .table .price_sale {
  color: red;
  text-decoration: line-through;
}

.services .table .action {
  width: 60px;
  text-align: center;
}

.services .table .num {
  width: 40px;
  text-align: center;
}

.services .image {
  max-width: 154px;
  vertical-align: middle;
  margin-right: 10px;
}

.services .info {
  vertical-align: middle;
  display: inline-block;
}

.services .name a {
  border-bottom: 1px dashed #000000;
  text-decoration: none;
  display: inline-block;
  margin-right: 5px;
  color: #000;
  margin-bottom: 10px;
}

.services .name a:hover {
  border-bottom: 1px dashed transparent;
}

.services .name svg {
  color: #b1b1b1;
}

.services .prompt {
  color: #6c6c6c;
}

.services .info-col>div {
  display: flex;
  align-items: center;
}

</style>
