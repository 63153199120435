import {createStore} from 'vuex';
import auth from "./modules/auth";
import cart from "./modules/cart";
import messenger from "./modules/messenger";
import breadcrumbs from "./modules/breadcrumbs";
import { getObjectsRequest, getParamsRequest } from "../api/orders";
import { getPagesRequest } from "../api/resource";

function findPageItem(array, alias) {
  for (let i=0; i<array.length; i++) {
    if (array[i].alias === alias) {
      return array[i];
    }
    if(array[i].items.length) {
      const item = findPageItem(array[i].items, alias);
      if(item !== undefined) {
        return item;
      }
    }
  }
}

function findPageItemByObject(array, objectId) {
  for (let i=0; i<array.length; i++) {
    if (array[i].objectId === objectId) {
      return array[i];
    }
    if(array[i].items.length) {
      const item = findPageItemByObject(array[i].items, objectId);
      if (item !== undefined) {
        return item;
      }
    }
  }
}

function findPageItemById(array, id) {
  for (let i=0; i<array.length; i++) {
    if (array[i].id === id) {
      return array[i];
    }
    if(array[i].items.length) {
      const item = findPageItemById(array[i].items, id);
      if (item !== undefined) {
        return item;
      }
    }
  }
}

export default createStore({
  state: {
    legalId: 1,
    template: null,
    objects: [],
    pricePaperReport: 0,
    loader: false,
    pages: []
  },
  actions: {
    loadObjects({ commit }) {
      return getObjectsRequest().then(response => {
        commit('setObjects', response.data);
        return response;
      });
    },
    loadPages({ commit }) {
      return getPagesRequest().then(response => {
        commit('setPages', response.data);
        return response;
      });
    },
    loadOrderParams({ commit }) {
      return getParamsRequest().then(response => {
        commit('setOrderParams', response.data);
        return response;
      });
    }
  },
  mutations: {
    setLegalId (state, value) {
      state.legalId = value
    },
    setTemplate (state, value) {
      state.template = value;
    },
    setObjects(state, objects) {
      state.objects = objects;
    },
    setPages (state, value) {
      state.pages = value;
    },
    setOrderParams (state, value) {
      state.pricePaperReport = value.pricePaperReport;
    },
    showLoader(state) {
      state.loader = true;
    },
    hideLoader(state) {
      state.loader = false;
    }
  },
  getters: {
    getPage: (state) => (path) => {
      const paths = path.split('/').filter(item => item !== '');
      if(!paths.length) {
        return findPageItem(state.pages, '/');
      } else if(paths.length === 1) {
        return findPageItem(state.pages, paths[0]);
      } else {
        const category = state.pages.find(category => category.alias === paths[0]);
        if(category !== undefined) {
          return findPageItem(category.items, paths[paths.length-1]);
        }
      }
    },
    getPageByObject: (state) => (objectId) => {
      return findPageItemByObject(state.pages, objectId);
    },
    getPageById: (state) => (id) => {
      return findPageItemById(state.pages, id);
    },
    findObjectById: (state) => (id) => {
      return state.objects.find(object => parseInt(object.id) === id);
    }
  },
  modules: {
    auth: auth,
    messenger: messenger,
    cart: cart,
    breadcrumbs: breadcrumbs
  },
})
