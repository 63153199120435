<script>
import {useStore} from "vuex";
import {computed} from "vue";

export default {
  name: "ReportTypeComponent",
  props: {
    reportType: {
      type: Number,
      required: true
    },
    handleChangeReportType: {
      type: Function,
      required: true
    }
  },
  setup() {
    const store = useStore();

    return {
      pricePaperReport: computed(() => store.state.pricePaperReport)
    }
  }
}
</script>

<template>
  <div class="report-type">
    <input type="checkbox" :checked="reportType === 2" @click="handleChangeReportType()"/> Отчет требуется также на бумажном носителе (+{{ pricePaperReport }} руб./отчет)
  </div>
</template>

<style scoped>

.report-type {
  text-align: left;
  margin-top: 10px;
}

.desktop .report-type {
  margin-left: 40px;
}

.report-type label {
  font-size: 14px;
  margin-bottom: 10px;
  display: inline-block;
  margin-right: 10px;
}

.report-type .double-switch {
  display: inline-block;
}

</style>
