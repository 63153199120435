<script>
export default {
  name: "PriceComponent",
  props: {
    price: {
      type: Number,
      required: true
    }
  }
}
</script>

<template>
  <div class="price">
    <b>
      Итого: {{ price }} руб.
      <span v-if="price > 2000" class="cart-info">
        При сумме заказа более 2000 руб. потребуется предоплата 50%.
      </span>
      <span v-if="price > 5000 && price <= 10000" class="cart-info">
        При сумме заказа более 5000 руб. по данному заказу будет дополнительная скидка 5%.
      </span>
      <span v-else-if="price > 10000 && price <= 15000" class="cart-info">
        При сумме заказа более 10000 руб. по данному заказу будет дополнительная скидка 10%.
      </span>
      <span v-else-if="price > 15000 && price <= 20000" class="cart-info">
        При сумме заказа более 15000 руб. по данному заказу будет дополнительная скидка 15%.
      </span>
      <span v-else-if="price > 20000" class="cart-info">
        При сумме заказа более 20000 руб. по данному заказу будет дополнительная скидка 20%.
      </span>
    </b>
  </div>
</template>

<style scoped>

.price {
  margin-top: 10px;
}

.desktop .price {
  margin-left: 40px;
}

</style>
