<template>
  <div class="step-3">
    <div class="cart-form">
      <form @submit="submitHandle">
        <h1>Данные заказчика оценки</h1>
        <div class="group-block">
          <span class="group-block-title">Паспортные данные</span>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label>ФИО заказчика</label>
                <dadata-suggestions
                  v-model="clientName.value"
                  type="NAME"
                  @change="formValidate()"
                  @focusout="formValidate()"
                  class="form-control"
                />
                <p class="error" v-if="clientName.error">{{ clientName.error }}</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label>Серия и номер паспорта</label>
                <input
                  type="text"
                  class="form-control"
                  v-mask="'#### ######'"
                  placeholder="#### ######"
                  v-model="passport.series.value"
                  @change="formValidate()"
                  @focusout="formValidate()"
                />
                <p class="error" v-if="passport.series.error">{{ passport.series.error }}</p>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label>Дата выдачи паспорта</label>
                <VueDatePicker
                  v-model="passport.date.value"
                  model-type="timestamp"
                  locale="ru"
                  :enable-time-picker="false"
                  auto-apply
                  format="dd.MM.yyyy"
                  @update:model-value="formValidate()"
                  text-input
                  @focusout="formValidate()"
                  utc="preserve"
                  @keyup="handleFormatDate"
                />
                <p class="error" v-if="passport.date.error">{{ passport.date.error }}</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label>Кем выдан паспорт</label>
                <dadata-suggestions
                  v-model="passport.from.value"
                  @change="formValidate()"
                  type="fms_unit"
                  class="form-control"
                  @focusout="formValidate()"
                />
                <p class="error" v-if="passport.from.error">{{ passport.from.error }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <i class="warning-text"><b>Важно!</b> Паспортные данные вводите аккуратно и внимательно. В целях безопасности мы не храним эти данные на сайте, а однократно передаем оценщику для указания в отчете. Вы не сможете их потом увидеть и перепроверить в личном кабинете.</i>
          </div>
        </div>
        <div class="step-nav">
          <div class="row">
            <div class="col-sm-6">
              <a href="#" @click="prevStepHandle">Вернуться назад</a>
            </div>
            <div class="col-sm-6">
              <button v-if="formValidate()" type="submit" class="btn-send-form">Отправить заказ на обработку</button>
              <button v-else type="button" disabled="disabled" class="disabled">Отправить заказ на обработку</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { parseDateFormat } from "../../../../../helpers/helper";
import { toRef } from "vue";

export default {
  name: 'Step3Component',
  props: {
    clientName: {
      type: Object,
      required: true
    },
    passport: {
      type: Object,
      required: true
    },
    submitHandle: {
      type: Function,
      required: true
    },
    prevStepHandle: {
      type: Function,
      required: true
    },
  },
  setup(props) {

    const clientName = toRef(props, 'clientName');
    const passport = toRef(props, 'passport');

      const handleFormatDate = (e) => {
          e.target.value = parseDateFormat(e.target.value);
      };

      const formValidate = () => {
        let error = false;
        const date = new Date().getTime();

        if(clientName.value.value === '') {
          clientName.value.error = 'Поле обязательно к заполнению';
          error = true;
        } else {
          clientName.value.error = '';
        }

        if(passport.value.series.value === '') {
          passport.value.series.error = 'Поле обязательно к заполнению';
          error = true;
        } else {
          passport.value.series.error = '';
        }

        if(passport.value.date.value === '' || passport.value.date.value === null) {
          passport.value.date.error = 'Поле обязательно к заполнению';
          error = true;
        } else {
          if((new Date(passport.value.date.value)).getTime() >= date) {
            passport.value.date.error = 'Дата не может быть больше текущей';
            error = true;
          } else {
            passport.value.date.error = '';
          }
        }

        if(passport.value.from.value === '' || passport.value.from.value === null) {
          passport.value.from.error = 'Поле обязательно к заполнению';
          error = true;
        } else {
          passport.value.from.error = '';
        }

        if(error) {
            return false;
        }

        return true;
      };

      return {
          formValidate,
          handleFormatDate
      }
  }
}
</script>

<style scoped>

  .step-3 {
    max-width: 600px;
    margin: 0 auto;
  }

  .step-3 .cart-form .mx-datepicker {
    width: 100%;
  }

  .step-3 .cart-form label {
    display: block;
  }

  .step-3 .cart-form .warning-text {
    font-size: 12px;
    border-radius: 10px;
    display: block;
  }

  .step-3 .cart-form .form-group {
    margin-bottom: 0;
    position: relative;
  }

  .step-3 .cart-form .row {
    margin-bottom: 15px;
  }

  .step-3 .cart-form .row:last-child {
    margin-bottom: 0;
  }

  .step-3 .cart-form .group-block {
    border: 1px solid #c7c7c7;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 15px;
    position: relative;
  }

  .step-3 .cart-form .group-block-title {
    position: absolute;
    top: -8px;
    left: 10px;
    background-color: #efefef;
    font-size: 10px;
    text-transform: uppercase;
    display: inline-block;
    padding: 2px;
    color: #000000;
  }

  .step-3 label {
    position: relative;
  }

  .step-3 .label-prompt {
    position: relative;
  }

  .step-3 .label-prompt>span {
    position: absolute;
    top: 0;
    left: 15px;
    border: 1px solid gray;
    padding: 5px;
    border-radius: 5px;
    background-color: #FFFFFF;
    font-weight: normal;
    font-size: 11px;
    max-width: 300px;
    width: max-content;
    width: -moz-max-content;
    z-index: 2;
    display: none;
  }

  .step-3 .label-prompt:hover>span {
    display: block;
  }

  .step-3 label .prompt-text {
    font-size: 12px;
    font-weight: normal;
    color: #606060;
  }

  .suggestions-wrapper {
    position: absolute;
    width: 100%;
    z-index: 2;
  }

  /*step-nav*/

  .step-nav {
    margin-top: 20px;
  }

  .step-nav .row>.col-sm-6:first-child {
    text-align: left;
  }

  .step-nav .row>.col-sm-6:last-child {
    text-align: right;
  }

  .step-nav a,
  .step-nav button {
    background-color: #ffffff;
    color: #1b1e21;
    border-radius: 5px;
    padding: 7px;
    border: 1px solid #c7c7c7;
    display: inline-block;
    text-decoration: none;
  }

  .step-nav .btn-send-form {
    background-color: #3194ca;
    color: #ffffff;
    border: none;
  }

  .step-nav .disabled {
    background-color: #d3d3d3;
  }

  @media (max-width: 768px) {
    .step-nav .col-sm-6 {
      width: 50%;
    }
  }

</style>
