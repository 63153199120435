<script>
export default {
  name: "NextButtonComponent"
}
</script>

<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="btn-cart">
        <router-link to="/cart/order">Перейти к загрузке документов</router-link>
      </div>
    </div>
  </div>
</template>

<style scoped>

.btn-cart {
  max-width: 300px;
}

.desktop .btn-cart {
  margin: 10px 0 0 auto;
}

.mobile .btn-cart {
  margin: 10px auto;
}

.btn-cart>a {
  display: block;
  text-align: center;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  font-weight: bold;
  background-color: #3194ca;
  color: #ffffff;
  box-shadow: 0 0 5px rgba(0,0,0,0.1);
  border: none;
  text-decoration: none;
}

</style>
