<script>
export default {
  name: "SearchDataComponent",
  props: {
    form: {
      type: Object,
      required: true
    },
    submitHandle: {
      type: Function,
      required: true
    },
    formatDateHandle: {
      type: Function,
      required: true
    }
  }
}
</script>

<template>
  <div>
    <span class="group-block-title">Данные свидетельства о смерти</span>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>ФИО наследодателя (умершего)</label>
          <dadata-suggestions
            v-model="form.name.value"
            type="NAME"
            class="form-control"
            @change="submitHandle()"
          />
          <p class="error" v-if="form.name.error">{{ form.name.error }}</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label>
            Дата оценки
            <span class="prompt-text">(дата смерти наследодателя)</span>
          </label>
          <VueDatePicker
            v-model="form.date.value"
            model-type="timestamp"
            locale="ru"
            :enable-time-picker="false"
            auto-apply
            format="dd.MM.yyyy"
            @closed="submitHandle()"
            text-input
            utc="preserve"
            @keyup="formatDateHandle"
            placeholder="__.__.____"
          />
          <p class="error" v-if="form.date.error">{{ form.date.error }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.mx-datepicker {
  width: 100%;
}

label {
  display: block;
}

.form-group {
  margin-bottom: 0;
  position: relative;
}

.row {
  margin-bottom: 15px;
}

.row:last-child {
  margin-bottom: 0;
}

.group-block-title {
  position: absolute;
  top: -8px;
  left: 10px;
  background-color: #efefef;
  font-size: 10px;
  text-transform: uppercase;
  display: inline-block;
  padding: 2px;
  color: #000000;
}

label .prompt-text {
  font-size: 12px;
  font-weight: normal;
  color: #606060;
}

</style>
