<script>
import ObjectsComponent from "./mobile/ObjectsComponent.vue";
import PriceComponent from "./PriceComponent.vue";
import ReportTypeComponent from "./ReportTypeComponent.vue";
import NextButtonComponent from "./NextButtonComponent.vue";

export default {
  name: "DesktopComponent",
  components: {
    ObjectsComponent,
    PriceComponent,
    ReportTypeComponent,
    NextButtonComponent
  },
  props: {
    objects: {
      type: Array,
      required: true
    },
    reportType: {
      type: Number,
      required: true
    },
    paperReportPrice: {
      type: Number,
      required: true
    },
    deleteItemEvent: {
      type: Function,
      required: true
    },
    handleChangeReportType: {
      type: Function,
      required: true
    },
    price: {
      type: Number,
      required: true
    }
  }
}
</script>

<template>
  <div class="mobile">
    <div class="row">
      <div class="col-sm-12">
        <h1>Нотариат</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="content">
          <report-type-component
            :report-type="reportType"
            :handle-change-report-type="handleChangeReportType"
          />
          <objects-component
            :objects="objects"
            :report-type="reportType"
            :paper-report-price="paperReportPrice"
            :delete-item-event="deleteItemEvent"
          />
          <price-component :price="price" />
        </div>
      </div>
    </div>
    <next-button-component />
  </div>
</template>

<style scoped>

</style>
