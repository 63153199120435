import HomeView from "../views/home/HomeView";
import CartView from "../views/cart/CartView";
import CartOrderView from "../views/cart/views/order/OrderView";
import NotFoundView from "../views/NotFoundView";
import ServicesView from "../views/services/ServicesView";
import ServiceView from "../views/services/views/service/ServiceView";
import SettingsView from "../views/settings/SettingsView";
import SettingsProfileView from "../views/settings/views/profile/ProfileView";
import OrdersView from "../views/orders/OrdersView";
import OrdersShareFilesView from "../views/orders/views/OrdersShareFilesView";
import PayResult from "../views/PayResult";
import AuthManager from "../views/auth/Manager";
import IndependenceView from "../views/IndependenceView";
import InformationView from "../views/information/InformationView";
import InformationPageView from "../views/information/views/InformationPageView";
import DefaultPageView from "../views/DefaultPageView";

const routes = [
  { path: '/home', redirect: '/' },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      auth: false
    }
  },
  {
    path: '/cart',
    name: 'cart',
    component: CartView
  },
  {
    path: '/cart/order',
    name: 'cart-order',
    component: CartOrderView,
    meta: {
      auth: true
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsView,
    redirect: '/settings/profile',
    meta: {
      auth: true
    },
    children: [
      {
        path: 'profile',
        name: 'settings-profile',
        component: SettingsProfileView,
        meta: {
          auth: true
        },
      }
    ]
  },
  {
    path: '/orders',
    name: 'orders',
    component: OrdersView,
    meta: {
      auth: true
    }
  },
  {
    path: '/orders/order/share-files',
    name: 'orders-order-share-files',
    component: OrdersShareFilesView
  },
  {
    path: '/oc',
    name: 'oc',
    component: ServicesView
  },
  {
    path: '/oc/:alias',
    name: 'oc-service',
    component: ServiceView,
    props: true
  },
  {
    path: '/pay-result',
    name: 'pay-result',
    component: PayResult
  },
  {
    path: '/auth/manager',
    name: 'auth-manager',
    component: AuthManager
  },
  {
    path: '/independence',
    name: 'independence',
    component: IndependenceView
  },
  {
    path: '/information',
    name: 'information',
    component: InformationView
  },
  {
    path: '/information/:alias',
    name: 'information-page',
    component: InformationPageView,
  },
  {
    path: '/policy',
    name: 'policy',
    component: DefaultPageView,
  },
  {
    path: '/how-to-order',
    name: 'how-to-order',
    component: DefaultPageView,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFoundView
  },
];

export default routes
