<script>
export default {
  name: "NextButtonComponent",
  props: {
    nextStepHandle: {
      type: Function,
      required: true
    },
    prevStepHandle: {
      type: Function,
      required: true
    },
    formValidateHandle: {
      type: Function,
      required: true
    }
  }
}
</script>

<template>
  <div class="step-nav">
    <div class="row">
      <div class="col-sm-6">
        <a href="#" @click="prevStepHandle">Вернуться назад</a>
      </div>
      <div class="col-sm-6">
        <button v-if="formValidateHandle(true)" type="submit" @click="nextStepHandle" class="active">Далее</button>
        <button v-else type="button" disabled="disabled" class="disabled">Далее</button>
      </div>
    </div>
  </div>
</template>

<style scoped>

.step-nav {
  margin-top: 20px;
}

.step-nav .row>.col-sm-6:first-child {
  text-align: left;
}

.step-nav .row>.col-sm-6:last-child {
  text-align: right;
}

.step-nav a,
.step-nav button {
  background-color: #ffffff;
  color: #1b1e21;
  border-radius: 5px;
  padding: 7px;
  border: 1px solid #c7c7c7;
  display: inline-block;
  text-decoration: none;
}

.step-nav .disabled {
  background-color: #d3d3d3;
}

.step-nav .active {
  background-color: #3294ca;
  color: #ffffff;
  border: none;
}

@media (max-width: 768px) {
  .step-nav .col-sm-6 {
    width: 50%;
  }
}

</style>
