<template>
  <layout-legacy-component>
    <breadcrumb-component/>
    <section class="cart">
      <template v-if="objects.length">
        <desktop-component
          :objects="objects"
          :report-type="reportType"
          :paper-report-price="pricePaperReport"
          :delete-item-event="handleDeleteCartItem"
          :handle-change-report-type="handleChangeReportType"
          :price="price"
        />
        <mobile-component
          :objects="objects"
          :report-type="reportType"
          :paper-report-price="pricePaperReport"
          :delete-item-event="handleDeleteCartItem"
          :handle-change-report-type="handleChangeReportType"
          :price="price"
        />
      </template>
      <template v-else>
        <div class="align-center">
          Корзина пуста
        </div>
      </template>
    </section>
  </layout-legacy-component>
</template>

<script>
import { ref, watchEffect, computed } from "vue";
import { useStore } from "vuex";
import { createToaster } from "@meforma/vue-toaster";
import { CART_DROP_ITEM_BY_INDEX, CART_EDIT_FIELDS, CART_GET_CART_DEFAULT } from "../../store/constants";
import LayoutLegacyComponent from "../../layouts/LayoutLegacy";
import BreadcrumbComponent from "../../components/BreadcrumbComponent";
import DesktopComponent from "./DesktopComponent.vue";
import MobileComponent from "./MobileComponent.vue";

export default {
    name: 'CartView',
    components: {
      LayoutLegacyComponent,
      BreadcrumbComponent,
      DesktopComponent,
      MobileComponent
    },
    setup() {

      const store = useStore();
      const cart = store.getters[CART_GET_CART_DEFAULT];
      const objects = ref([]);
      const price = ref(0);
      const pricePaperReport = computed(() => store.state.pricePaperReport);
      const reportType = computed(() => cart.type);
      const toaster = createToaster({ position: 'top' });

      const initCart = () => {
        price.value = 0;
        objects.value = cart.items.map(item => {
          const object = store.getters.findObjectById(item.id);
          object.dbIndex = item.index;
          if(object.priceSale) {
            price.value += object.priceSale;
          } else {
            price.value += object.price;
          }
          object.page = store.getters.getPageByObject(object.id);
          return object;
        });
        if(cart.type === 2) {
          price.value += (pricePaperReport.value*objects.value.length);
        }
      };

      const handleDeleteCartItem = (itemIndex) => {
        store.dispatch(CART_DROP_ITEM_BY_INDEX, itemIndex);
        toaster.success('Успешно удалено из корзины');
      };

      const handleChangeReportType = () => {
        if(!cart.type || cart.type === 1) {
          store.dispatch(CART_EDIT_FIELDS, { index: 'type', value: 2 });
        } else {
          store.dispatch(CART_EDIT_FIELDS, { index: 'type', value: 1 });
        }
      };

      watchEffect(() => {
        initCart();
      });

      return {
        objects,
        price,
        pricePaperReport,
        reportType,
        handleDeleteCartItem,
        handleChangeReportType
      }
    }
};
</script>

<style scoped>

</style>
