<template>
  <div class="files">
    <template v-if="isArchive">
      <a href="#" class="archive-button" @click="handleRecoverReport">Файлы перемещены в архив, кликните чтобы вернуть</a>
    </template>
    <template v-else>
      <span>Скачать: </span>
      <div class="list">
        <file-component
          v-for="(file, index) in files"
          :key="file.id"
          :file="file"
          :folder-name="folderName"
          :is-last="files.length === (index+1)"
        />
      </div>
      <span v-if="(allReportConfirmed && !isFullPayed) || (isFullPayed && !isSigned)" class="info">
        (файлы ЭЦП будут доступны после оплаты)
      </span>
      <actions-component v-if="finalReportStatusValue === 1 || finalReportStatusValue === 5" :show-event="handleShow"/>
      <form-component v-if="isShowForm" :hide-event="handleHide"/>
    </template>
  </div>
</template>

<script>
import { ref, inject } from 'vue';
import { reArchiveRequest } from "../../../../../../../../api/orders";
import FileComponent from "./file/FileComponent";
import ActionsComponent from "./actions/ActionsComponent";
import FormComponent from "./form/FormComponent";
export default {
    name: "FilesComponent",
    components: {
        FileComponent,
        ActionsComponent,
        FormComponent
    },
    props: {
        files: {
            type: Array,
            required: true
        },
        finalReportStatusValue: {
            type: Number,
            required: true
        },
        folderName: {
            type: String,
            required: true
        },
        allReportConfirmed: {
            type: Boolean,
            required: true
        },
        isFullPayed: {
            type: Boolean,
            required: true
        },
        isSigned: {
            type: Boolean,
            required: true
        },
        isArchive: {
            type: Number,
            required: true
        }
    },
    setup() {

        const isShowForm = ref(false);
        const orderId = inject('orderId');
        const reportId = inject('reportId');
        const loader = inject('loader');
        const searchOrders = inject('searchOrders');

        const handleShow = () => {
            isShowForm.value = true;
        };

        const handleHide = () => {
            isShowForm.value = false;
        };

        const handleRecoverReport = (e) => {
            e.preventDefault();
            loader.value = true;
            reArchiveRequest(orderId, reportId)
                .then(() => {
                    searchOrders().then(() => {
                        loader.value = false;
                    });
                })
                .catch(err => console.log(err));
        };

        return {
            isShowForm,
            handleHide,
            handleShow,
            handleRecoverReport
        }
    }
}
</script>

<style scoped>

  .files {
    font-size: 16px;
    margin-top: 5px;
    color: #555555;
  }

  .files .list {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    max-width: 820px;
  }

  .files .info {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
  }

  .files>span {
    color: #049f04;
    font-weight: 500;
  }

  .files .archive-button {
    color: red;
    font-style: italic;
    text-decoration: underline;
  }

  .files .archive-button:hover {
    text-decoration: none;
  }

  @media (max-width: 992px) {

    .files .list {
      display: block;
    }

  }

</style>
